<template>
  <div>
     <!-- BOC:[error] -->
  <AErrorV1 :api="api"></AErrorV1>
  <!-- EOC -->
    <v-select
      :label="$t('model.name.school')"
      key="id"
      class="white"
      :items="data"
      item-text="name"
      item-value="id"
      v-model="inputVal"
      :loading="api.isLoading"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["value"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    code: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.code = resp[0].code;
    };
    //EOC
  },
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    fetch() {
      this.api.url = `${this.$api.servers.classroom}/api/migrate/v1/school`;
      this.$api.fetch(this.api);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>
